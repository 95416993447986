import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Je suis <span className="purple">Thierno BANGOURA </span>
            de <span className="purple"> Paris.</span>
            <br />
            Je travaille actuellement en tant que développeur chez Arenametix.
            <br />
            J'ai un <span className="purple">master en Informatique logiciels</span> et une <span className="purple">licence en mathématiques informatiques</span> à Institut d'électronique et d'informatique <span className="purple">Gaspard-Monge (UPEM).</span>
            <br />
            <br />
            En dehors du code, voici d'autres activités que j'aime faire !
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Jeuxvideo
            </li>
            <li className="about-activity">
              <ImPointRight /> Lecture (Tech, Roman, Mangas...)
            </li>
            <li className="about-activity">
              <ImPointRight /> Sports (Basketball, Football...)
            </li>
            <li className="about-activity">
              <ImPointRight /> Voyager
            </li>
          </ul>

          {/*<p style={{ color: "rgb(155 126 172)" }}>*/}
          {/*  "Strive to build things that make a difference!"{" "}*/}
          {/*</p>*/}
          {/*<footer className="blockquote-footer">Thierno</footer>*/}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
