import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
// import leaf from "../../Assets/Projects/leaf.png";
// import emotion from "../../Assets/Projects/emotion.png";
// import editor from "../../Assets/Projects/codeEditor.png";
// import chatify from "../../Assets/Projects/chatify.png";
// import suicide from "../../Assets/Projects/suicide.png";
// import bitsOfCode from "../../Assets/Projects/blog.png";

import projectA from "../../Assets/Projects/projectA.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Mes <strong className="purple">Projets </strong>
        </h1>
        <p style={{ color: "white" }}>
        Voici quelques projets sur lesquels j'ai travaillé récemment.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={projectA}
              isBlog={false}
              title="projectA"
              description="Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression."
              ghLink="https://github.com/UnMecLambda/ChatHack"
              demoLink="https://www.thierno-bangoura.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={projectA}
              isBlog={false}
              title="projectA"
              description="Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression."
              ghLink="https://github.com/UnMecLambda/ChatHack"
              demoLink="https://www.thierno-bangoura.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={projectA}
              isBlog={false}
              title="projectA"
              description="Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression."
              ghLink="https://github.com/UnMecLambda/ChatHack"
              demoLink="https://www.thierno-bangoura.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={projectA}
              isBlog={false}
              title="projectA"
              description="Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression."
              ghLink="https://github.com/UnMecLambda/ChatHack"
              demoLink="https://www.thierno-bangoura.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={projectA}
              isBlog={false}
              title="projectA"
              description="Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression."
              ghLink="https://github.com/UnMecLambda/ChatHack"
              demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={projectA}
              isBlog={false}
              title="projectA"
              description="Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500"
              ghLink="https://github.com/UnMecLambda/ChatHack"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley"      
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
